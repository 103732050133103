import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {SnackbarService} from '../../services/snackbar.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  title: string;
  message: string;
  SNACKBAR_STATES = {
    SUCCESS: 0,
    ERROR: 0,
    WARN: 0,
  };

  constructor(public snackbarService: SnackbarService,
              @Inject(MAT_SNACK_BAR_DATA) public data: { state: number, message: string }) {
  }

  ngOnInit(): void {
    this.message = this.data.message;
  }

  close(): void {
    this.snackbarService.dismiss();
  }

}
