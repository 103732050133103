import {Injectable} from '@angular/core';
import {AVAILABLE_ENVIRONMENTS} from '../constant/constant';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  constructor() {
  }

  public displayChatSupport = new BehaviorSubject<boolean>(true);

  getSurveyCurrentEnvironment(): string {
    return localStorage.getItem('currentEnvironment') ?? AVAILABLE_ENVIRONMENTS.TEST;
  }
}
