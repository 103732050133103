import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './components/footer/footer.component';
import {AdminOrgEnvSelectionComponent} from './components/admin-org-env-selection/admin-org-env-selection.component';
import {FormsModule} from '@angular/forms';
import {OrganizationRolePipe} from './pipes/OrganizationRole.pipe';
import {AlertComponent} from './components/alert/alert.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SnackbarService} from './services/snackbar.service';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import { SpinnerComponent } from './components/spinner/spinner.component';
import {IntercomComponent} from './components/intercom/intercom.component';

@NgModule({
  declarations: [
    FooterComponent,
    AdminOrgEnvSelectionComponent,
    OrganizationRolePipe,
    AlertComponent,
    SpinnerComponent,
    IntercomComponent
  ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        FormsModule,
        MatButtonModule,
        RouterModule,
    ],
  providers: [SnackbarService],
  exports: [FooterComponent, AdminOrgEnvSelectionComponent, OrganizationRolePipe, SpinnerComponent, IntercomComponent]
})
export class SharedModule {
}
