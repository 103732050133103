import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AlertComponent} from '../components/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  // duration
  private durationSeconds = 2000;
  SNACKBAR_STATES = {
    SUCCESS: 0,
    ERROR: 0,
    WARN: 0,
  };

  constructor(private snackBar: MatSnackBar) {
  }

  public success(successMessage: string, durationSeconds: number = this.durationSeconds) {

    this.snackBar.openFromComponent(AlertComponent, {
      data: {
        state: this.SNACKBAR_STATES.SUCCESS,
        message: successMessage,
      },
      duration: durationSeconds,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-notify-container'
    });

  }

  public error(errorMessage: string) {

    this.snackBar.openFromComponent(AlertComponent, {
      data: {
        state: this.SNACKBAR_STATES.ERROR,
        message: errorMessage
      },
      duration: this.durationSeconds,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error-notify-container'
    });
  }

  public warn(warnMessage: string) {

    this.snackBar.openFromComponent(AlertComponent, {
      data: {
        state: this.SNACKBAR_STATES.WARN,
        message: warnMessage
      },
      duration: this.durationSeconds,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'warn-notify-container'
    });
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }
}
