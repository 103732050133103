<div class="alert-container">
  <div class="d-flex justify-content-between align-items-center">
    <span>
      {{ this.message }}
    </span>
    <button mat-icon-button class="float-end mt-1 ml-3 text-white" (click)="close()">
      Close
    </button>
  </div>
</div>
