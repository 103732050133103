import {Injectable} from '@angular/core';
import {UtilService} from './util.service';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Organization} from '../models/organization.model';
import {PulseUser} from '../models/pulse-user.model';
import {DecodedIdToken} from '../models/idToken.model';
import {getAuth, signOut, sendPasswordResetEmail, onAuthStateChanged} from 'firebase/auth';
import {collection, getFirestore, doc, getDoc} from 'firebase/firestore';
import {COLLECTIONS} from '../constant/constant';
import {ApiUrl} from '../helpers/ApiUrl';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SharedAuthService {
  private currentUserFirestoreDataSubject = new BehaviorSubject<PulseUser>(null);
  currentUserFirestoreData = this.currentUserFirestoreDataSubject.pipe(filter(currentUser => currentUser !== null));

  private organization = new BehaviorSubject<Organization>(null);
  getCurrentOrganization = this.organization.pipe(filter(currentOrg => currentOrg !== null));

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private router: Router
  ) {
  }

  async getCurrentUserRole(): Promise<number> {
    const decodedToken = await this.getCurrentUserDecodedIdToken();
    return Number(decodedToken?.custom_claims?.role);
  }

  async doLogout(): Promise<void> {
    const auth = getAuth();
    await signOut(auth);
    localStorage.removeItem('currentEnvironment');
    this.currentUserFirestoreDataSubject.next(null);
    this.organization.next(null);
    this.router.navigateByUrl('');
  }

  async forgotPassword(email: string) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
  }

  async getCurrentUserDecodedIdToken(refresh = false): Promise<DecodedIdToken> {
    return new Promise<DecodedIdToken>(async (resolve, reject) => {
      const isLoggedIn = await this.isUserLoggedIn();
      if (!isLoggedIn) {
        resolve(null);
      }
      const auth = getAuth();
      try {
        const idToken = await auth.currentUser.getIdToken(refresh);
        resolve(this.utilService.decodeJWTToken(idToken));
      } catch (error) {
        reject(error);
      }
    });
  }

  async getCurrentOrganizationId(): Promise<string> {
    const decodeToken = await this.getCurrentUserDecodedIdToken();
    return decodeToken?.custom_claims?.organization;
  }

  async isCurrentUserPartner(): Promise<boolean> {
    const decodedToken = await this.getCurrentUserDecodedIdToken();
    return !!decodedToken?.custom_claims?.is_partner;
  }

  /*isCurrentUserPartnerAsync(): Promise<boolean> {
    return new Promise((resolve) => {
      this.getCurrentUserDecodedIdToken().subscribe((decodedToken: DecodedIdToken) => {
        resolve(!!decodedToken?.custom_claims?.is_partner);
      });
    });
  }*/

  async setCurrentUserFirestoreData(currentUserId: string = null) {
    const isLoggedIn = await this.isUserLoggedIn();
    if (!isLoggedIn) {
      return;
    }

    if (!currentUserId) {
      currentUserId = await this.getCurrentUserIdAsync();
    }

    const db = getFirestore();

    try {
      // Get the user document
      const userDocRef = doc(collection(db, COLLECTIONS.USERS), currentUserId);
      const userDocSnap: any = await getDoc(userDocRef);

      if (userDocSnap.exists) {
        const currentUser: PulseUser = userDocSnap.data();
        console.log('currentUser', currentUser);

        // Check for org_id and fetch organization data if present
        if (currentUser?.org_id) {
          const organizationDocRef = doc(collection(db, COLLECTIONS.ORGANIZATIONS), currentUser.org_id);
          const organizationDocSnap: any = await getDoc(organizationDocRef);

          if (organizationDocSnap.exists) {
            currentUser.organization = organizationDocSnap.data();
            console.log('currentUser.organization', currentUser.organization);
            this.currentUserFirestoreDataSubject.next(currentUser);
          } else {
            console.log('Organization document not found');
          }
        } else {
          // Update currentUserSubject
          this.currentUserFirestoreDataSubject.next(currentUser);
        }
      } else {
        console.log('User document not found');
      }
    } catch (error) {
      console.error('Error accessing user data:', error);
    }
  }

  setCurrentUserOrganization() {
    this.getCurrentOrganizationId()
      .then(async organizationId => {
        if (organizationId) {
          const db = getFirestore();
          try {
            // Get the organization document
            const organizationDocRef = doc(collection(db, COLLECTIONS.ORGANIZATIONS), organizationId);
            const organizationDocSnap = await getDoc(organizationDocRef);

            if (organizationDocSnap.exists) {
              const organizationData: any = organizationDocSnap.data();
              this.organization.next(organizationData);
            } else {
              console.log('Organization document not found');
            }
          } catch (error) {
            console.error('Error accessing organization data:', error);
          }
        }
      });
  }

  getCurrentUserIdSync(): string | null {
    const auth = getAuth();
    return auth.currentUser.uid;
  }

  login(payload) {
    return this.http.post(ApiUrl.LOGIN_VALID_USER, payload);
  }

  isUserLoggedIn(): Promise<boolean> {
    const auth = getAuth();

    // If auth.currentUser exists, the user is already logged in
    if (auth.currentUser) {
      return Promise.resolve(true);
    }

    // Otherwise, check the auth state using onAuthStateChanged
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }


  private getCurrentUserIdAsync(): Promise<string> {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(user.uid); // Resolve the promise with the uid
        } else {
          resolve(null);
        }
      }, (error) => {
        reject(error);
      });
    });
  }
}
