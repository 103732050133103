// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isLocalEnv: false,
  firebase: {
    apiKey: 'AIzaSyBjQSf4KeWEbPAnRT_mAneX-TLgh4474BM',
    authDomain: 'meander-pulse-staging.firebaseapp.com',
    projectId: 'meander-pulse-staging',
    storageBucket: 'meander-pulse-staging.appspot.com',
    messagingSenderId: '599856906672',
    appId: '1:599856906672:web:f0ded6853802edd5f35675',
    measurementId: 'G-LE5BH0LQH3'
  },
  appVersion: '1.0.0-staging',
  whereUserIs: 'https://www.travelpayouts.com/whereami',
  appCheckSiteKey: '6Les72okAAAAADUIBlppzJf5i2SUAbDuAef5Ak0k',
  FareUpThereAPI: 'https://api-staging.meander.travel/v1/',
  disposableEmail: 'https://open.kickbox.com/v1/disposable/',
  branch_io: 'key_test_oyfiFQQOBksSQnWKht7zqphczxinQgTo',
  web_survey_url: 'https://staging.surveys.meander.travel/',
  stripePublicKey: 'pk_test_2wGPVEMIN7k1eJ6vbaX6JGaa00mbTWmFyA'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
