import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {DecodedIdToken} from '../models/idToken.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }

  // Add remove lottie player script to show lottie player
  addRemoveLottiePlayerScript(addScript: boolean) {
    if (addScript) {
      // JS
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js';
      script.id = 'lottie-player-script';
      document.head.appendChild(script);
    } else {
      const scriptElem = document.getElementById('lottie-player-script');
      scriptElem.remove();
    }
  }

  addRemoveStripeScript(addScript: boolean) {
    if (addScript) {
      // JS
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://js.stripe.com/v3/';
      script.id = 'stripeV3';
      document.head.appendChild(script);
    } else {
      const scriptElem = document.getElementById('stripeV3');
      scriptElem.remove();
    }
  }

  decodeJWTToken(token: string): DecodedIdToken {
    return jwt_decode(token);
  }

  generateUniqueId() {
    const timestamp = new Date().getTime();
    return timestamp.toString();
  }

  addBranchIo() {
    const script = document.createElement('script');
    script.id = 'branchIo';
    script.text = `(function (b, r, a, n, c, h, _, s, d, k) {
      if (!b[n] || !b[n]._q) {
        for (; s < _.length;) c(h, _[s++]);
        d = r.createElement(a);
        d.async = 1;
        d.src = "https://cdn.branch.io/branch-latest.min.js";
        k = r.getElementsByTagName(a)[0];
        k.parentNode.insertBefore(d, k);
        b[n] = h
      }
    })(window, document, "script", "branch", function (b, r) {
      b[r] = function () {
        b._q.push([r, arguments])
      }
    }, {
      _q: [],
      _v: 1
    }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
    branch.init('${environment.branch_io}');`;
    document.head.appendChild(script);
  }
}



